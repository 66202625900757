import { RouteObject } from 'react-router';
import BaseLayout from 'src/layouts/BaseLayout';
import Status404 from './pages/errors/Status404';
import Login from './pages/auth/Login';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const ProtectedLoginPage = withAuthenticationRequired(Login);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <ProtectedLoginPage />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
